@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu {
    @apply grid
      grid-cols-1
      bg-white
      rounded-lg
      pl-3;
  }

  .menu .menuChild:not(:last-child) .menuItemContent {
    @apply border-b;
  }

  .menuItem {
    @apply flex
      flex-row
      flex-nowrap
      items-stretch;
  }

  .menuItem .menuItemContent {
    @apply flex-1
      flex
      flex-row
      flex-nowrap
      items-center
      py-2
      pr-3
      gap-4;
  }
}
